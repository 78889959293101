<template>
  <div>
    <!-- banner section Starts -->
    <section class="banner">
      <img src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg" alt="img" class="img-fluid banner-section-img" />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-9 col-lg-6">
            <div class="card profile p-5">
              <div class="profile_card d-flex justify-content-center">
                <div class="pro_details text-center">
                  <img :src="(avatar) ? avatar : '../../assets/images/home-essentials/user-image.png'" alt="">

                  <h2>{{ first_name }} {{ last_name }}</h2>
                  <div class="border-bottom-1"></div>
                  <h3><span> Email: </span> {{ email }}</h3>

                  <button class="button red mt_40" data-bs-toggle="modal" data-bs-target="#exampleModal6">
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal6" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-arbnb">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="getProfile">
                X
              </a>
            </div>
            <form class="p-4" @submit.prevent="editProfile" data-vv-scope="addCientForm">
              <div class="file-upload">
                <div class="image-upload-wrap">
                  <input class="file-upload-input" type="file" accept="image/*" @change="onFileChange" />
                  <div class="drag-text">
                    <h3>Drag and drop profile image</h3>
                  </div>
                  <div class="uploadedImages">
                    <img :src="(avatar) ? avatar : '../../assets/images/home-essentials/user-image.png'" alt="">
                  </div>
                </div>

              </div>
              <div class="form-group">
                <!-- <label class="font_size_24 mb_20 font_bold">Name</label> -->
                <input type="text" placeholder="First name" class="mb_50 form-control" v-model="first_name" required />
              </div>
              <div class="form-group">
                <!-- <label class="font_size_24 mb_20 font_bold">Name</label> -->
                <input type="text" placeholder="Last name" class="mb_50 form-control" v-model="last_name" required />
              </div>
              <div class="form-group">
                <!-- <label class="font_size_24 mb_20 font_bold">Email</label> -->
                <input type="text" placeholder="Email address" class="mb_50 form-control opacity-50" v-model="email"
                  readonly disabled />
              </div>
              <div class="mt_68 text-center">
                <button type="submit" class="button red">
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner section Ends -->
  </div>
</template>
<script>
  //import commonFunction from "../mixin/commonFunction";
  export default {
    name: "airbnb_profile",
    //mixins: [commonFunction],
    data() {
      return {
        first_name: null,
        last_name: null,
        email: null,
        avatar: null,
        base_url: null,
        isLoading: false,
      };
    },
    mounted() {
      this.email = localStorage.getItem("employeeEmail");
      this.base_url = process.env.BASE_URL;
      this.getProfile();
    },
    methods: {
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.createImage(files[0]);
      },
      createImage(file) {
        var reader = new FileReader();

        reader.onload = (e) => {
          this.avatar = e.target.result;
        };

        reader.readAsDataURL(file);
      },
      editProfile() {
        if (localStorage.getItem("userToken")) {
          this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/profile-update",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
            },
            data: {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              avatar: this.avatar,
            },
          };
          this
            .axios(config)
            .then(() => {
              this.$router.go();
            })
            .catch(({
              response
            }) => {
              console.log(response);
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            });
        } else {
          this.tokenExpired();
        }
      },
      getProfile() {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "get",
          url: process.env.VUE_APP_API_URL + "/api/get-profile",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        };
        this
          .axios(config)
          .then(({
            data
          }) => {
            this.first_name = data.user.first_name;
            this.last_name = data.user.last_name;
            this.email = data.user.email;
            this.avatar = data.user.avatar;
            this.$parent.$refs.airbnbheadercompnent.avatar = data.user.avatar;
            window.localStorage.setItem("user_profile", data.user.avatar);
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({
            response
          }) => {
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            console.log(response);
            if (response.data.type == false) {
              this.$router.push({
                name: "login"
              });
            }
          });
      },
    },
  };
</script>